import React from 'react'
import { Link } from 'gatsby'

import { Container, Row, Col } from 'reactstrap'

import Layout from '../components/layout'

const SoonPage = () => (
  <div className="container d-flex soon">
    <div className="row justify-content-center align-self-center w-100">
      <img src="/assets/me_profile.jpg" className="rounded-circle" alt="" />
      <h1 className="text-center">new website under construction</h1>
    </div>
  </div>
)

export default SoonPage
